import { useNavigate, Link } from "react-router-dom";
import { aboutAkashGangaData } from "../../components/sellPageForeign/data";
import PeraTextData from "../../components/global/PeraTextData";
/*eslint-disable*/
import AakashgangaHeader from "../../components/global/HeaderComponent";
import AakashgangaFooter from "../../components/global/FooterComponent";
import JoinPublishingCommunityForm from "../../components/sellPageForeign/JoinPublishingCommunityForm";
import { Helmet } from 'react-helmet';
import societyBg from "../../assets/img/icons/society-bg.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect,useState } from "react";

function SocietyHomepageNEW() {
  const [societyJournal, setSocialJournal] = useState()
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };
  const handleContent = (type) => {
    const element = document.getElementById(`viewScroll${type}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const masterData = useSelector((state) => state?.MasterDataReducer?.MasterData);

  console.log("masterData",masterData)
  const fetchSociety = () => {
    const SocietyJournal = masterData?.allJournal?.filter(
      (journal) => journal.societyName === "society"
    ) ?? [];
    
    console.log("SocietyJournal", SocietyJournal);
    setSocialJournal(SocietyJournal);
  }
  const dateToYear = (date) => {
    return new Date(date).getFullYear();
  }
  const getEndDateMonthNames = (data) => {
    return data.map(item => {
        const date = new Date(item.endDate);
        return date.toLocaleString('default', { month: 'long' });
    }).join(', ');
  }
  const formatUrl = (inputString) => {
    if (!inputString) {
      return ''; // Return an empty string or handle the case appropriately
  }
    let formattedString = inputString.toLowerCase(); 
    formattedString = formattedString.replace(/\s/g, '-');
    return formattedString;
  }
  const findSubjectName = (data) => {
    var inputString = masterData?.subjectdata?.filter(
      (subject) => subject._id === `${data}`
    ) ?? [];

    var inputString = inputString?.[0]?.subjectName;

    if (!inputString) {
      return ''; // Return an empty string or handle the case appropriately
  }
    let formattedString = inputString.toLowerCase(); 
    formattedString = formattedString.replace(/\s/g, '-');
    return formattedString;
  }
  

  useEffect(() => {
    fetchSociety();
  },[masterData])

  const handleClick = () => {
    window.scrollTo({
      top: 4500,
      behavior: "smooth",
    });
  };
  return (
    <>
     <Helmet>
          <title> Society Journals</title>
          {/* <meta name="description" content="Learn how Aakashganga, an Indian open access journal platform, supports this initiative by providing world-class publishing infrastructure at Indian prices." />

          <meta property="og:title" content="Indian Societies Partnership|  Aakashganga Indian open access journals platform" data-react-helmet="true" />
          <meta property="og:description" content="Learn how Aakashganga, an Indian open access journal platform, supports this initiative by providing world-class publishing infrastructure at Indian prices." data-react-helmet="true" />
          <meta property="og:url" content="https://www.aakashgangaopen.in/domestic-societies-partnerships" data-react-helmet="true" /> */}

     <link rel="canonical" href="https://www.aakashgangaopen.in/society-journals" data-react-helmet="true" />
    </Helmet>
      <div className="tagbody">
        <AakashgangaHeader />
        <h1 className="seo-related-tag">
          
        </h1>
        <main className="main society-newpage">
          <div className="">
            <div className="container p-0">
              <div className="sell-banner-wrp">
                <div className="sell-banner">
                  <img
                    className="tagimg object-fit-cover rounded-0"
                    src={societyBg}
                    alt=""
                  />
                </div>
                <div className="sell-banner-text">
                  <h3 className="title tagh3 fs-60">
                  Society Journals
                  </h3>
                  
                  
                </div>
              </div>
            </div> 
          </div>
          <div className="">
            <div className="container container-full Transforming-btm-col py-5">
              <div className="row soci-row">
                {societyJournal?.map((journal, index) => (
                  <div className="col-md-6">
                  <div className="d-flex soci-item gap-3 ">
                    <div className="soci-m-img overflow-hidden">
                      <img src={journal?.masthead} />
                    </div>
                    <div className="soci-m-content">
                      <Link 
                      to={`/society-journals/${findSubjectName(journal?.subjectData?.[0]?.subject)}/${journal?.slug}`}
                       ><h3 className="fw-bold">{journal?.journalName}</h3></Link>
                      <div className="d-flex">
                      <ul className="list-unstyled">
                        <li>e-ISSN: </li>
                        <li>Frequency: </li>
                        <li>Format: </li>
                        <li>Debut year: </li>
                        <li>Language: </li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>{journal?.issnNumber?journal?.issnNumber:"XXXX-XXXX"}</li>
                        <li>{getEndDateMonthNames(journal?.volumeIssue?.issues)}</li>
                        <li>Online Journal</li>
                        <li>{dateToYear(journal?.volumeIssue?.startDate)}</li>
                        <li>English</li>
                      </ul>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
                {/* <div className="col-md-6">
                  <div className="d-flex soci-item gap-3 ">
                    <div className="soci-m-img">
                      <img src="https://i.postimg.cc/q79tSDM3/image.png" />
                    </div>
                    <div className="soci-m-content">
                      <h3 className="fw-bold">Minorities in world Politics</h3>
                      <div className="d-flex">
                      <ul className="list-unstyled">
                        <li>e-ISSN: </li>
                        <li>Frequency: </li>
                        <li>Format:: </li>
                        <li>Debut year: </li>
                        <li>Language: </li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>2581-639X</li>
                        <li>April, August, December</li>
                        <li>Online Journal</li>
                        <li>2024</li>
                        <li>English</li>
                      </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex soci-item gap-3 ">
                    <div className="soci-m-img">
                      <img src="https://i.postimg.cc/q79tSDM3/image.png" />
                    </div>
                    <div className="soci-m-content">
                      <h3 className="fw-bold">Minorities in world Politics</h3>
                      <div className="d-flex">
                      <ul className="list-unstyled">
                        <li>e-ISSN: </li>
                        <li>Frequency: </li>
                        <li>Format:: </li>
                        <li>Debut year: </li>
                        <li>Language: </li>
                      </ul>
                      <ul className="list-unstyled">
                        <li>2581-639X</li>
                        <li>April, August, December</li>
                        <li>Online Journal</li>
                        <li>2024</li>
                        <li>English</li>
                      </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </main>
        <AakashgangaFooter />
      </div>
    </>
  );
}

export default SocietyHomepageNEW;
