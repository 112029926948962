
import React, { useRef,useEffect, useState,useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';

import {  UploadMedia } from '../../store/apiCalls/profileDetails';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex'
import 'katex/dist/katex.min.css'
import "../../containers/autherFlow/personalDetails/PersonalDetails.scss";


const SunRichText = ({ hide=false, style, name, handleChange,uploadedImg, value, dataId= '',manageCiatation,refranceRef }) => {
    const dispatch = useDispatch();
    const editor = useRef();
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    useImperativeHandle(refranceRef, () => ({
        citationInRefrance(details) {
            editor.current.insertHTML(details, false, true, false)
        },
        getEditorContent() {
           return  editor.current.getContents()
        }
      }));
    useEffect(() => {
      }, []);
    
    function onChange(content) {
       
      
        handleChange(content, 'added')
    }

    function handleImageUploadBefore(files, info, uploadHandler) {
        uploadHandler(files, info)
    }
    const escapeRegExpMatch = function (s) {
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
      };
    const isExactMatch = (str, match) => {
        return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
      };
    async function handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
        
       
        if(imageInfo && isExactMatch(imageInfo.src, 'base64')){
            try {
                const fileForm=dataURLtoFile(imageInfo.src,imageInfo.name);
                
                dispatch({ type: 'SET_LOADER', payload: true});
                const formData = new FormData();
                let reader = new FileReader();
                formData.append("file", fileForm);
               
                const uploadResp = await UploadMedia(formData);
                if(uploadResp.status===200)
                {
                    targetImgElement.src = uploadResp.data.url;
                    uploadedImg(uploadResp.data.url)
                    handleChange(editor.current.getContents(), 'added') 
                }
                else{
                    alert('s3 bucket upload error')
                }
                dispatch({ type: 'SET_LOADER', payload: false});
            } catch(err) {
                dispatch({ type: 'SET_LOADER', payload: false});
            }
        }
        else if(imageInfo){
            targetImgElement.src = imageInfo.src ;
            uploadedImg(imageInfo.src)
        }
        else if(imageInfo===null){
            
            handleChange(editor.current.getContents(), 'removeImg') 
        }
    }

    function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = window.atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }
    
    function onKeyUp(e) 
    {
         
    }
    
    function getMenuList()
    {
       const button= [
            "undo",  // we can delete the button from ui which we do not need .
            "redo",
            // "font",   
            // "fontSize",
            //"formatBlock",
            //"paragraphStyle",
            "blockquote",
            "bold",
            "underline",  // we can delete the button from ui which we do not need .
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
           // "hiliteColor",
           // "textStyle",
            //"removeFormat",
            "outdent",
            "indent",
           "align",
            "horizontalRule",
            "list",
           // "lineHeight",
            "table",
            "link",
            "image",
            "fullScreen",
            //"showBlocks",
           // "codeView",
           // "preview",
          //  "print",
            "math",
            // "video",
            // "audio",
            // "imageGallery",
            // "save",
             
        ];
        if(name==='Abstract' || name==='Introduction' || name==='References')
        {
            button.splice(18, 1);
        }

        return button;
    } 
    function htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
      
        const removeInlineStyles = (element) => {
          return element.textContent; // Return only the text content
        };
      
        return input;
      }
    function onPaste(event, cleanData, maxCharCount, core) 
    {
        const detail= htmlDecode(cleanData)
        // console.log(detail);
        
        if(detail.includes('generatedCitation')===true)
        {
            const splitDetail= detail.split("Extract");
            if(splitDetail.length>0)
            {
                editor.current.insertHTML(splitDetail[0], true, true, true)
                
                manageCiatation(splitDetail[1]) 
                return false
            }
            
        }
        else{
            editor.current.insertHTML(detail);
            return false;
        }
        
    }

    return (
        <>
        
     <SunEditor
            setDefaultStyle="font-size: 14px;font-family:Calibri"
            getSunEditorInstance={getSunEditorInstance}
            hide={hide}
            lang= 'en'
            setOptions={{  
                katex:katex ,
                height: 300,
                charCounter : true,
                charCounterLabel: "character count:",
                buttonList: [getMenuList()],
                font:[],
                attributesWhitelist: { // Add attributesWhitelist here
                    all: "style",
                },
                fontSize:12,
                colorList:'#000000' ,
                
            }}
            onChange={onChange}
            onImageUploadBefore={handleImageUploadBefore}
            onImageUpload={handleImageUpload}
            setContents={value}
            onPaste={onPaste}
            
        />


       </>
    );
};
export default SunRichText